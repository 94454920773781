exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-digital-marketing-js": () => import("./../../../src/pages/digital-marketing.js" /* webpackChunkName: "component---src-pages-digital-marketing-js" */),
  "component---src-pages-get-a-quote-js": () => import("./../../../src/pages/get-a-quote.js" /* webpackChunkName: "component---src-pages-get-a-quote-js" */),
  "component---src-pages-hosting-services-js": () => import("./../../../src/pages/hosting-services.js" /* webpackChunkName: "component---src-pages-hosting-services-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-pricing-structure-js": () => import("./../../../src/pages/legal/pricing-structure.js" /* webpackChunkName: "component---src-pages-legal-pricing-structure-js" */),
  "component---src-pages-legal-privacy-policy-js": () => import("./../../../src/pages/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-js" */),
  "component---src-pages-legal-refund-policy-js": () => import("./../../../src/pages/legal/refund-policy.js" /* webpackChunkName: "component---src-pages-legal-refund-policy-js" */),
  "component---src-pages-legal-terms-conditions-js": () => import("./../../../src/pages/legal/terms-conditions.js" /* webpackChunkName: "component---src-pages-legal-terms-conditions-js" */),
  "component---src-pages-search-engine-optimisation-js": () => import("./../../../src/pages/search-engine-optimisation.js" /* webpackChunkName: "component---src-pages-search-engine-optimisation-js" */),
  "component---src-pages-social-media-marketing-js": () => import("./../../../src/pages/social-media-marketing.js" /* webpackChunkName: "component---src-pages-social-media-marketing-js" */),
  "component---src-pages-web-application-development-js": () => import("./../../../src/pages/web-application-development.js" /* webpackChunkName: "component---src-pages-web-application-development-js" */),
  "component---src-pages-website-design-and-development-js": () => import("./../../../src/pages/website-design-and-development.js" /* webpackChunkName: "component---src-pages-website-design-and-development-js" */),
  "component---src-pages-website-maintenance-js": () => import("./../../../src/pages/website-maintenance.js" /* webpackChunkName: "component---src-pages-website-maintenance-js" */)
}

